import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type PropsTypes = {
    size?: 'small'
}

function LogoBuddiesSpicy({ size }: PropsTypes) {
    const [isHovered, setIsHovered] = useState(false)
    const { t } = useTranslation()

    return (
        <div
            className={`gap-smallLogo lg:gap-normalLogo flex items-center justify-center ${size === 'small' && 'gap-smallLogo lg:gap-smallLogo'}`}
        >
            <img
                className={`w-small-icon-width lg:w-icon-width ${size === 'small' && 'w-small-icon-width lg:w-small-icon-width'}`}
                src={
                    isHovered
                        ? '/buddies-spicy-icon-hover.svg'
                        : '/buddies-spicy-icon.svg'
                }
                alt=""
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
            <div
                className={`text-smallLogo lg:text-normalLogo flex flex-col font-nunito font-black uppercase leading-[54px] ${size === 'small' && 'text-smallLogo lg:text-smallLogo'}`}
            >
                {t('application.name')}
                <span
                    className={`mt-[-28px] font-permanentMarker text-[26px] text-[#FD3B31] lg:mt-0 lg:text-[44px] ${size === 'small' && 'text-text-[26px] mt-[-28px] lg:mt-[-28px] lg:text-[26px]'}`}
                >
                    spicy
                </span>
            </div>
        </div>
    )
}

export default LogoBuddiesSpicy
