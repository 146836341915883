import React from 'react'
import { gameBackgroundsColors, gameType } from '../constants'
import BadgeAppStore from './BadgeAppStore'
import BadgeGooglePlay from './BadgeGooglePlay'

type PropsTypes = {
    type: keyof typeof gameType
    children: React.ReactNode
}

function AppTile({ type, children }: PropsTypes) {
    const backgroundColor =
        type === gameType.buddies
            ? gameBackgroundsColors.buddies
            : gameBackgroundsColors.buddiesSpicy

    return (
        <section
            className="relative flex max-h-[372.62px] w-[100%] max-w-[690.78px] items-center justify-center rounded-[19.11px] bg-[#FFCF9F] px-[3.5vw] py-[50px] lg:h-[372.62px] lg:py-0"
            style={{ backgroundColor: backgroundColor }}
        >
            {children}
            <nav className="absolute bottom-[-20px] flex gap-[20px] lg:bottom-[-35px]">
                <BadgeGooglePlay type={type} />
                <BadgeAppStore type={type} />
            </nav>
        </section>
    )
}

export default AppTile
