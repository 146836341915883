export const defaultLanguage = 'en'
export const acceptLanguages = ['en', 'pl', 'uk', 'de']

export interface PropsGameType {
    buddies: 'buddies'
    buddiesSpicy: 'buddiesSpicy'
}

export const gameType: PropsGameType = {
    buddies: 'buddies',
    buddiesSpicy: 'buddiesSpicy',
}

export const gameBackgroundsColors = {
    buddies: '#FFCF9F',
    buddiesSpicy: '#FFC6C6',
}
