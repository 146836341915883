import React from 'react'
import { useTranslation } from 'react-i18next'
import { gameType } from '../constants'

type PropsTypes = {
    type: keyof typeof gameType
}

function BadgeAppStore({ type }: PropsTypes) {
    const { t, i18n } = useTranslation()
    const { language } = i18n

    return (
        <a
            href={
                type === gameType.buddies
                    ? t('appstoreBadge.urlBuddies')
                    : t('appstoreBadge.urlBuddiesSpicy')
            }
            target="_blank"
            rel="noreferrer"
        >
            <img
                className="h-[45px] lg:h-[75px]"
                src={`/locales/${language}/download-from-app-store.svg`}
                alt={t('appstoreBadge.alt')}
            />
        </a>
    )
}

export default BadgeAppStore
