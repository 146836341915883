import React from 'react'
import { useTranslation } from 'react-i18next'

type PropsTypes = {
    size?: 'small'
}

function LogoBuddies({ size }: PropsTypes) {
    const { t } = useTranslation()

    return (
        <div
            className={`gap-smallLogo lg:gap-normalLogo flex items-center justify-center ${size === 'small' && 'gap-smallLogo lg:gap-smallLogo'}`}
        >
            <img
                className={`w-small-icon-width lg:w-icon-width ${size === 'small' && 'w-small-icon-width lg:w-small-icon-width'}`}
                src="/kumple-icon.svg"
                alt=""
            />
            <span
                className={`text-smallLogo lg:text-normalLogo font-nunito font-black uppercase ${size === 'small' && 'text-smallLogo lg:text-smallLogo'}`}
            >
                {t('application.name')}
            </span>
        </div>
    )
}

export default LogoBuddies
