import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import {getFirstBrowserLanguage, isLanguageSupported} from './helpers';
import {defaultLanguage} from "./constants";
import App from './App';

import './index.css';

import en from './locales/en/en.json';
import pl from './locales/pl/pl.json';
import de from './locales/de/de.json';
import uk from './locales/uk/uk.json';

let userBrowserLanguage = getFirstBrowserLanguage();

let siteLanguage = defaultLanguage;

if (userBrowserLanguage.length > 1) {
    userBrowserLanguage = userBrowserLanguage.slice(0,2)
}

if (isLanguageSupported(userBrowserLanguage)) {
    siteLanguage = userBrowserLanguage;
}

i18n.init({
  interpolation: { escapeValue: false },
  lng: siteLanguage,
  resources: {
    de: de,
    en: en,
    pl: pl,
    uk: uk
  },
});

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18n}>
          <App/>
      </I18nextProvider>
  </React.StrictMode>
);