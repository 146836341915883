import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { isLanguageSupported } from './helpers'
import React from 'react'
import AppTile from './components/AppTile'
import LogoBuddies from './components/LogoBuddies'
import LogoBuddiesSpicy from './components/LogoBuddiesSpicy'
import { gameType } from './constants'

function LandingPage() {
    const { lang = '' } = useParams()
    const { t, i18n } = useTranslation()
    const [params] = useSearchParams()

    useEffect(() => {
        if (isLanguageSupported(lang)) {
            i18n.changeLanguage(lang)
        }

        const linkParam = params.get("link")

        if (linkParam) {
          const userAgent = navigator.userAgent
          const partyIosUrl =
            "https://apps.apple.com/app/buddies-party-game/id1615045598"
          const partyAndroidUrl =
            "https://play.google.com/store/apps/details?id=com.kumple"
          const extremeIosUrl = "https://apps.apple.com/app/kumple/id6504534032"
          const extremeAndroidUrl = "https://play.google.com/store/apps/details?id=com.kumple.extreme"
          const isExtreme = window.location.href.includes("extreme")

          if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.replace(isExtreme ? extremeIosUrl : partyIosUrl)
          } else if (/android/i.test(userAgent)) {
            window.location.replace(isExtreme ? extremeAndroidUrl : partyAndroidUrl)
          } else {
            window.location.replace("/") // Redirect to home if not iOS or Android
          }
        }
    }, [])
    
    return (
        <main className="h-[100%] w-[100%] items-center justify-center bg-[#F4F0EC] px-[20px] py-[60px] font-nunito lg:flex">
            <header>
                <div
                    className="mb-[40px] w-[291px] font-nunito text-[40px] font-black leading-[39px] lg:w-auto lg:text-[56px]"
                    dangerouslySetInnerHTML={{
                        __html: t('application.description'),
                    }}
                ></div>
                <nav className="flex flex-col items-center justify-center gap-[60px] lg:flex-row lg:gap-[30px]">
                    <AppTile type={gameType.buddies}>
                        <LogoBuddies />
                    </AppTile>
                    <AppTile type={gameType.buddiesSpicy}>
                        <LogoBuddiesSpicy />
                    </AppTile>
                </nav>
            </header>
        </main>
    )
}

export default LandingPage
