import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page from "./Page";
import LandingPage from "./LandingPage";

const router = createBrowserRouter([
  {
    path: "/:lang?",
    element: <LandingPage />,
  },
  {
    path: "/:lang?/privacy-policy",
    element: <Page type="page-privacy-policy" />,
  },
  {
    path: "/:lang?/support",
    element: <Page type="page-support" />,
  },
]);

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("header.title");
  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
